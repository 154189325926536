import api from "@/axios";

export default class AlertaService {

    getAlertas() {
        return api.get('api/ssalertaindex' ).then((res) => res.data);
    }

    getAlerta(id) {
        const params = { id }
        return api.get('api/ssalertaget', {params}).then((res) => res.data);
    }

    saveAlerta(alerta) {
        return api.post('api/ssalertasave', {alerta} ).then((res) => res.data);
    }

    deleteAlerta(id) {
        const params = { id }
        return api.get('api/ssalertadel', { params }).then((res) => res.data);
    }

}
